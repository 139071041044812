export const Roles = {
    administrator: {
        administrator: 0
    },
    branch: {
        branch: 1,
        add: 2,
        update: 3,
        delete: 4,
        showAll: 5
    },
    group: {
        group: 6,
        add: 7,
        update: 8,
        delete: 9,
        showAll: 10
    },
    user: {
        user: 11,
        add: 12,
        update: 13,
        delete: 14,
        showAll: 15
    },
    event: {
        window: 16,
        create: 17,
        update: 18,
        delete: 19
    },
    alert: {
        window: 20,
        create: 21,
        update: 22,
        delete: 23,
        alertDestinations: 27
    },
    complaint: {
        window: 24,
        handle: 25,
        reply: 26
    },
    structure: {
        window: 28,
        create: 29,
        update: 30,
        delete: 31
    },
    nightShift: {
        window: 32,
        create: 33,
        delete: 34
    },
    companyWebsite: {
        get: 35,
        create: 36,
        update: 38,
        delete: 39
    },
    deathAnnouncements: {
        deathAnnouncements: 40,
        create: 41,
        update: 42,
        delete: 43
    },
    employeesManagement: {
        employeesManagement: 44,
        printPasswords: 45,
        search: 46,
        changePassword: 47,
        blockStatusChange: 48,
        logoutAll: 49
    },
    policy: {
        policy: 50,
        update: 51
    }


};
