<template>
    <v-navigation-drawer
        v-model="drawer"
        id="core-navigation-drawer"
        :right="$vuetify.rtl"
        app
        width="300"
        v-bind="$attrs"
    >
        <v-list dense nav class="ma-0 pa-0">
            <v-list-item class="ma-0 pa-0">
                <v-btn
                    style="padding: 31px !important"
                    class="ma-0"
                    color="primary darken-2"
                    tile
                    block
                    depressed
                    to="/"
                >
                    <v-img
                        class="ma-auto"
                        max-width="48"
                        src="../assets/AlameedLogo.png"
                    >
                    </v-img>
                </v-btn>
            </v-list-item>
        </v-list>
        <v-list class="mb-10" nav>
            <template v-for="(item, i) in myItems.filter(f => f.visible)">
                <v-list-group
                    exact
                    :key="i"
                    :prepend-icon="item.icon"
                    v-if="item.children"
                    v-model="item.active"
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ $t(item.title) }}</v-list-item-title
                            >
                        </v-list-item-content>
                    </template>
                    <v-list-item
                        active-class="primary white--text"
                        v-for="(subItem, ind) in item.children.filter(
                            f => f.visible
                        )"
                        :key="ind"
                        :to="subItem.to"
                    >
                        <v-list-item-icon> </v-list-item-icon>
                        <v-list-item-title class="mx-n2">
                            <v-icon>mdi-circle-small</v-icon>
                            {{ $t(subItem.title) }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>

                <v-list-item
                    v-else
                    :to="item.to"
                    :key="item"
                    active-class="primary"
                >
                    <v-list-item-icon>
                        <v-icon>
                            {{ item.icon }}
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                </v-list-item>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
import { mapState } from "vuex";
export default {
    name: "DashboardCoreDrawer",
    props: {
        expandOnHover: {
            type: Boolean,
            default: false
        }
    },
    created() {
        var path='/';
        var paths=this.$route.path.split('/');
        if(paths.length>1){
            path+=paths[1];
        }else{
            path+=paths[0];
        }


        var childs = this.myItems.filter(item => {
            return (
                item.children &&
                item.children.some(it => {
                    return it.to == path || it.to == this.$route.path;
                })
            );
        });

        if (childs && childs.length > 0) {
            childs[0].active = true;
        }
    },
    computed: {
        ...mapState(["barColor", "barImage"]),
        drawer: {
            get() {
                return this.$store.state.drawer;
            },
            set(val) {
                this.$store.commit("SET_DRAWER", val);
            }
        },
        computedItems() {
            var indexes = [];
            var items = JSON.parse(JSON.stringify(this.items));

            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                if (item.role) {
                    if (!this.isInRole(item.role)) {
                        items[i] = null;
                    }
                }
                var childRoles = [];
                var child = item.children;
                if (child) {
                    for (var j = 0; j < child.length; j++) {
                        var subItem = child[j];

                        if (subItem.role) {
                            if (!this.isInRole(subItem.role)) {
                                child[j] = null;
                            } else {
                                childRoles.push(subItem.role);
                            }
                        }
                    }
                    item.children = child.filter(f => {
                        return f != null && f != "null";
                    });
                }

                if (childRoles.length == 0 && !item.role) {
                    indexes.push(i);
                }
            }

            for (var m = 0; m < indexes.length; m++) {
                items.splice(indexes[m], 1);
            }

            return items.filter(f => {
                return f != null && f != "null";
            });
        },
        profile() {
            return {
                avatar: true
            };
        },
        myItems() {
            var list = [
                {
                    icon: "mdi-view-dashboard-outline",
                    title: "Dashboard",
                    group: "dashboard",
                    visible:
                        this.$store.getters.isInRole(this.roles.group.group) ||
                        this.$store.getters.isInRole(this.roles.user.user) ||
                        this.$store.getters.isInRole(this.roles.branch.branch),
                    children: [
                        {
                            title: "groups.groups",
                            to: "/groups",
                            role:this.roles.group.group,
                            visible: this.$store.getters.isInRole(
                                this.roles.group.group
                            )
                        },
                        {
                            icon: "mdi-account",
                            title: "users.users",
                            to: "/users",
                            role: this.roles.user.user,
                            visible: this.$store.getters.isInRole(
                                this.roles.user.user
                            )
                        },
                        {
                            title: "branches.branches",
                            icon: "mdi-clipboard-outline",
                            to: "/branch",
                            role:this.roles.branch.branch,
                            visible: this.$store.getters.isInRole(
                                this.roles.branch.branch
                            )
                        }
                    ]
                },
                {
                    icon: "mdi-account-tie-outline",
                    title: "main.managementAffairs",
                    group: "management",
                    visible:
                        this.$store.getters.isInRole(
                            this.roles.structure.window
                        ) ||
                        this.$store.getters.isInRole(
                            this.roles.employeesManagement.employeesManagement
                        ) ||
                        this.$store.getters.isInRole(
                            this.roles.companyWebsite.get
                        ) ||
                        this.$store.getters.isInRole(
                            this.roles.nightShift.window
                        ) ||
                        this.$store.getters.isInRole(
                            this.roles.policy.policy
                        ),
                    children: [
                        {
                            icon: "mdi-account",
                            title: "employees.employees",
                            to: "/changeEmployeeSettings",
                            role:  this.roles.employeesManagement.employeesManagement,
                            visible: this.$store.getters.isInRole(
                                this.roles.employeesManagement.employeesManagement
                            )
                        },
                        {
                            title: "nightshifts.nightshifts",
                            icon: "mdi-clipboard-outline",
                            to: "/nightshifts",
                            role: this.roles.nightShift.window,
                            visible: this.$store.getters.isInRole(
                                this.roles.nightShift.window
                            )
                        },
                        {
                            icon: "mdi-account",
                            title: "structures.structure",
                            to: "/ManagementStructure",
                            role: this.roles.structure.window,
                            visible: this.$store.getters.isInRole(
                                this.roles.structure.window
                            )
                        },
                        {
                            icon: "mdi-account",
                            title: "companyWebsites.companyWebsites",
                            to: "/CompanyWebsites",
                            visible: this.$store.getters.isInRole(
                                this.roles.companyWebsite.get
                            )
                        },
                        {
                            icon: "mdi-account",
                            title: "policy.policy",
                            to: "/Policy",
                            visible: this.$store.getters.isInRole(
                                this.roles.policy.policy
                            )
                        }
                    ]
                },
                {
                    icon: "mdi-account-group-outline",
                    title: "main.employeesAffairs",
                    group: "employees",
                    visible:
                        this.$store.getters.isInRole(
                            this.roles.complaint.window
                        )||this.$store.getters.isInRole(
                            this.roles.deathAnnouncements.deathAnnouncements
                        ),
                    children: [
                        {
                            title: "complaints.complaints",
                            icon: "mdi-clipboard-outline",
                            to: "/Complaints",
                            role: this.roles.complaint.window,
                            visible: this.$store.getters.isInRole(
                                this.roles.complaint.window
                            )
                        },
                        {
                            icon: "mdi-account",
                            title: "death.deathAnnouncements",
                            to: "/DeathAnnouncements",
                            role:this.roles.deathAnnouncements.deathAnnouncements,
                            visible: this.$store.getters.isInRole(
                                this.roles.deathAnnouncements.deathAnnouncements
                            )
                        }
                        /* {
                            icon: "mdi-account",
                            title: "surveys.surveys",
                            to: "/Surveys",
                            role: "0",
                            visible: this.$store.getters.isInRole(this.roles.administrator.administrator)
                        } */
                    ]
                },
                {
                    icon: "mdi-account-alert-outline",
                    title: "alerts.alerts",
                    group: "alerts",
                    visible:
                        this.$store.getters.isInRole(this.roles.alert.window) ||
                        this.$store.getters.isInRole(this.roles.alert.create) ||
                        this.$store.getters.isInRole(this.roles.alert.update),
                    children: [
                        {
                            title: "alerts.drawer.showAlerts",
                            icon: "mdi-clipboard-outline",
                            to: "/alerts",
                            role:  this.roles.alert.window,
                            visible: this.$store.getters.isInRole(
                                this.roles.alert.window
                            )
                        },
                        {
                            title: "alerts.alertToDepartments",
                            icon: "mdi-clipboard-outline",
                            to: "/AlertToDepartments",
                            role: this.roles.alert.window,
                            visible:
                                this.$store.getters.isInRole(
                                    this.roles.alert.create
                                ) ||
                                this.$store.getters.isInRole(
                                    this.roles.alert.update
                                )
                        },
                        {
                            title: "alerts.alertToEmployees",
                            icon: "mdi-clipboard-outline",
                            to: "/AlertToEmployees",
                            role:  this.roles.alert.window,
                            visible:
                                this.$store.getters.isInRole(
                                    this.roles.alert.create
                                ) ||
                                this.$store.getters.isInRole(
                                    this.roles.alert.update
                                )
                        },
                        {
                            title: "alerts.alertToPositions",
                            icon: "mdi-clipboard-outline",
                            to: "/AlertToPositions",
                            role:  this.roles.alert.window,
                            visible:
                                this.$store.getters.isInRole(
                                    this.roles.alert.create
                                ) ||
                                this.$store.getters.isInRole(
                                    this.roles.alert.update
                                )
                        }
                    ]
                },
                {
                    icon: "mdi-calendar-text",
                    title: "events.events",
                    group: "events",
                    visible: this.$store.getters.isInRole(
                        this.roles.event.window
                    ),
                    children: [
                        {
                            title: "events.eventTypes.news",
                            icon: "mdi-clipboard-outline",
                            to: "/events/0",
                            role: this.roles.event.window,
                            visible: this.$store.getters.isInRole(
                                this.roles.event.window
                            )
                        },
                        {
                            title: "events.eventTypes.advertisements",
                            icon: "mdi-clipboard-outline",
                            to: "/events/1",
                            role: this.roles.event.window,
                            visible: this.$store.getters.isInRole(
                                this.roles.event.window
                            )
                        },
                        {
                            title: "events.eventTypes.projects",
                            icon: "mdi-clipboard-outline",
                            to: "/events/2",
                            role: this.roles.event.window,
                            visible: this.$store.getters.isInRole(
                                this.roles.event.window
                            )
                        },
                        {
                            title: "events.eventTypes.blogs",
                            icon: "mdi-clipboard-outline",
                            to: "/events/3",
                            role: this.roles.event.window,
                            visible: this.$store.getters.isInRole(
                                this.roles.event.window
                            )
                        },
                        {
                            title: "events.eventTypes.quotes",
                            icon: "mdi-clipboard-outline",
                            to: "/events/4",
                            role: this.roles.event.window,
                            visible: this.$store.getters.isInRole(
                                this.roles.event.window
                            )
                        }
                    ]
                }
            ];
            return list;
        }
    },
    methods: {
        mapItem(item) {
            return {
                title: item.title,
                icon: item.icon,
                group: item.group
            };
        }
    }
};
</script>
<style>
.v-navigation-drawer__content {
    padding-bottom: 100px !important;
}
</style>
<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24



    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
