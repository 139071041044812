<template>
    <base-material-card
        :icon="icon"
        class="v-card--material-stats mt-4"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template
            v-slot:after-heading
            class="text-right text-h3 v-card__subtitle--material-stats"
        >
            <div class="ml-auto text-right">
                <div
                    style="font-size: 14px"
                    class="grey--text font-weight-light"
                    v-text="title"
                />

                <div
                    class="display-1 font-weight-light text--primary v-card-material-stats"
                >
                    {{ value }} <small>{{ smallValue }}</small>
                </div>
            </div>
        </template>

        <v-col cols="12" class="px-0">
            <v-divider />
        </v-col>

        <v-icon
            v-if="!subTextLink"
            :color="subIconColor"
            size="16"
            class="ml-2 mr-1"
        >
            {{ subIcon }}
        </v-icon>

        <span
            v-if="!subTextLink"
            :class="subTextColor"
            class="caption grey--text font-weight-light"
            v-text="subText"
        />

        <v-btn
            v-if="subTextLink"
            text
            color="primary"
            :to="subTextLink"
            x-small
        >
            <v-icon small>
                {{ subIcon }}
            </v-icon>
            {{ subText }}
        </v-btn>
    </base-material-card>
</template>

<script>
import Card from "./Card";

export default {
    name: "MaterialStatsCard",

    inheritAttrs: false,

    props: {
        ...Card.props,
        icon: {
            type: String,
            required: true
        },
        subIcon: {
            type: String,
            default: undefined
        },
        subIconColor: {
            type: String,
            default: undefined
        },
        subTextColor: {
            type: String,
            default: undefined
        },
        subText: {
            type: String,
            default: undefined
        },
        title: {
            type: String,
            default: undefined
        },
        value: {
            type: String,
            default: undefined
        },
        smallValue: {
            type: String,
            default: undefined
        },
        subTextLink: {
            type: String,
            default: undefined
        }
    }
};
</script>

<style lang="sass">
.v-card--material-stats
  display: flex
  flex-wrap: wrap
  position: relative

  > div:first-child
    justify-content: space-between

  .v-card
    border-radius: 4px
    flex: 0 1 auto

  .v-card__text
    display: inline-block
    flex: 1 0 calc(100% - 120px)
    position: absolute
    top: 0
    right: 0
    width: 100%

  .v-card__actions
    flex: 1 0 100%

  .v-card-material-stats
   font-size: 1.8625rem !important
   font-weight: 300
   line-height: 1.4em
   letter-spacing: normal !important
   font-family: "Roboto", sans-serif !important
</style>
<style>
.v-application .text-right {
    text-align: end !important;
}
</style>
