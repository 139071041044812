import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

// export default new Vuetify({
//     icons: {
//         iconfont: 'md',
//   },
// });


export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.teal,
        secondary: colors.teal.darken3,
        accent: colors.shades.black,
        error: colors.red.accent3,
        background: colors.black
      },
      dark: {
        primary: colors.teal.darken2,
        secondary: colors.teal.darken3,
        accent: colors.shades.black,
        error: colors.red.accent3,
      },
    },
  },
})
