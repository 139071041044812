import "devextreme/dist/css/dx.material.blue.light.compact.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { store } from "./store/store";
import i18n from "./i18n";
import "./plugins/base";
import Vue2Filters from "vue2-filters";
import axios from "axios";
import { VueMaskDirective } from "v-mask";
import DatetimePicker from "vuetify-datetime-picker";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import jwt_decode from "jwt-decode";
import VueSplash from "vue-splash";
import browserDetect from "vue-browser-detect-plugin";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import "vuetify/dist/vuetify.min.css";
import VueHtmlToPaper from "vue-html-to-paper";

Vue.use(VueSplash);
Vue.use(VueHtmlToPaper);

Vue.use(browserDetect);
const moment = require("moment");

var currentLanguage = localStorage.getItem("lang");

if (currentLanguage == null || currentLanguage == undefined) {
    localStorage.setItem("lang", "ar");
    localStorage.setItem("rtl", true);
    localStorage.setItem("lang", "ar");
}

var drawerState = localStorage.getItem("drawer");

if (drawerState == null || drawerState == undefined)
    localStorage.setItem("drawer", true);

moment.locale(localStorage.getItem("lang") == "en" ? "en" : "ar-dz");

Vue.use(require("vue-moment"), { moment });

Vue.use(Toast, {
    position: localStorage.rtl === "true" ? "bottom-left" : "bottom-right",
    timeout: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    closeButton: "button",
    icon: true,
    rtl: localStorage.rtl === "true"
});

Vue.use(DatetimePicker);

Vue.use(Vue2Filters);
Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: "md"
});

import { Roles } from "./BusinessLogic/Roles";
import { ResponseStatus } from "./BusinessLogic/ResponseStatus";

Vue.mixin({
    data() {
        return {
            responseStatus: ResponseStatus,
            roles: Roles,
            dateNow: new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 10)
        };
    },
    methods: {
        isInRole(role) {
            var roles = jwt_decode(localStorage.token).role;
            if (typeof roles == "string") return roles == role;

            return roles.filter(f => f == role).length > 0;
        },
        redirectUnauthorizedUsers() {
            this.$router.push({
                name: "notauthorize"
            });
        }
    }
});

Vue.config.devtools = false;

Vue.config.productionTip = false;
Vue.directive("mask", VueMaskDirective);

axios.defaults.withCredentials = true;

if (axios.defaults.baseURL === "" || axios.defaults.baseURL === undefined) {
    axios.get("/static/config.json").then(res => {
        axios.defaults.baseURL = res.data.VUE_APP_URL;
    });
}

axios.defaults.headers.get.Accepts = "application/json";
axios.interceptors.request.use(
    config => {
        store.commit("overlay", true);

        const token = localStorage.getItem("token");
        const auth = token ? `Bearer ${token}` : "";
        config.headers.common.Authorization = auth;
        return config;
    },
    error => Promise.reject(error)
);

axios.interceptors.response.use(
    function(response) {
        store.commit("overlay", false);
        return response;
    },
    function(error) {
        store.commit("overlay", false);
        if (
            error.response.status === 401 ||
            error.response.status === 403
        ) {
            router.push({ name: "notauthorize" });
        }
        return Promise.reject(error);
    }
);

new Vue({
    store: store,
    router,
    axios,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount("#app");
